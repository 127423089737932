$mainColor: rgb(0, 0, 0);
$sko-grey: rgb(53, 53, 53);
$primary: $sko-grey;
$sko-color: rgba(255, 255, 255, 1);

$sko-bg-color: rgba(0, 0, 0, 0.5);
$body-bg: $sko-bg-color;
$body-color: $sko-color;
$border-color: rgba(40, 40, 40, 0.9);


$link-color: lighten($primary, 50);
//$link-hover-color: lighten($primary,30);
$theme-colors: (
        "primary": $primary
);
$colors: (
        "primary": $primary
);

$sko-bg: linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.02));
$sko-switch-checked-color: #391db0;
//$dropdown-link-hover-bg: rgba(255, 255, 255, 0.085);


$nav-tabs-border-color: lighten(red, 2);
$nav-tabs-link-hover-border-color: rgba(0, 0, 0, 0);
$nav-tabs-link-active-border-color: rgba(0, 0, 0, 0);
$nav-tabs-link-active-bg: rgba(0, 0, 0, 0);
$nav-tabs-link-active-color: $sko-color;


$custom-control-indicator-checked-bg: $sko-switch-checked-color;
$custom-control-indicator-checked-border-color: lighten($sko-switch-checked-color, 10);
/*
$table-dark-bg: lighten($sko-bg-color, 10);
$table-dark-accent-bg: lighten($table-dark-bg, 20);
$table-dark-border-color: lighten($table-dark-bg, 10);
$table-dark-hover-bg: lighten($table-dark-bg, 40);
*/
$table-color: $sko-color;
$table-hover-bg: darken($sko-grey, 5);
$font-weight-base: 200; //reduce font width